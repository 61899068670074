// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// $gray-dark:				 #cc0000 !default;

$enable-flex: true;

$navbar-light-hover-color: #f4f4f4;

$brand-primary: #48C8F0 !default;

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    #007bff !default;
$red:     #e8334b !default;
$yellow:  #e5e500 !default;
$green:   #28a745 !default;
$cyan:    #17a2b8 !default;
$theme-colors: (
  primary: #48C8F0,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

$navbar-top-background: #ffffff !default;
$navbar-bottom-background: #ffffff !default;

$navbar-top-color: #888;
$navbar-top-active-color: #888;
$navbar-top-hover-color: #000;

$navbar-bottom-color: #888;
$navbar-bottom-active-color: #888;
$navbar-bottom-hover-color: #000;

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/demora/src/resources/assets/scss/scss.scss';
